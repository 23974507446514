import https from "./https"
import store from "@/store"

const orgId = store.state.general.organization.id

const suite = {
  list (params) {
    return https.get(`admin/organizations/${orgId}/suites`, { params })
  },
  set (params) {
    return https.post(`admin/organizations/${orgId}/suites/set`, params)
  }
}

export default suite
